.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg {
 
  background-size:cover;
  background-position:center;
  background-image:url('./Assets/bg.jpg');
  background-repeat: no-repeat;
  height: 100vh;

}


.container {
  display: flex;
  overflow-x: auto;
  padding-bottom: 30px;
  margin-left: 50px;
  margin-top: 50px;
  width:100vw;
  margin-bottom: 50px;

}

.container img {
  margin-right: 40px;
  height: 25vw;
  width: 25vw;
  padding-bottom: 8px;
}
.container::-webkit-scrollbar {
  width: 2em;
}

.container::-webkit-scrollbar-track {
  background:#EDE9E3;
}

.container::-webkit-scrollbar-thumb {
  background:black;
  width: 10;
  border-radius: 100vw;
  border: 0.5em solid #EDE9E3;
}



.mobilecontain {
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
  margin-left: 30px;
  margin-top: 50px;
  margin-bottom: 50px;

}

.mobilecontain img {
  margin-right: 40px;
  height: 25vh;
  width: 25vh;
  padding-bottom: 8px;
}
.mobilecontain::-webkit-scrollbar {
  width: 2em;
}

.mobilecontain::-webkit-scrollbar-track {
  background:#EDE9E3;
}

.mobilecontain::-webkit-scrollbar-thumb {
  background:black;
  width: 10;
  border-radius: 100vw;
  border: 0.5em solid #EDE9E3;
}

.App-link {
  color: #61dafb;
}

.img:hover{
    transition: 500ms;
    transform: scale(1.02);
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.arrow {
  position: absolute;
  top: 80%;
  left: 51%;
  transform: translate(-50%, -50%);
  transform: rotate(0deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}