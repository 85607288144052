.footer{
    display: flex;
    flex-direction: row;
    width: 100%;
  
    text-align: left;
    background-size:cover;
    background-position:center;

    
    }
    
    .footer.mobile{
        flex-direction: column;
    }
    
    .link{
  
        padding-bottom: 8px;
        font-size: 15px;
        color: grey;
        transition: 100ms;
        text-decoration: none;
    }
    
    .link:hover{
    transition: 100ms;
      font-size: 20px;
      color: black;
    
    }
    a:hover{
        text-decoration: none;
    }
    

    .icon{
        color: grey;
        margin-bottom: 12px;
        height: 18px;
        width: 18px;
        margin-right: 10px;
    }