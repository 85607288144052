
    .about {
  
        position:static;
        height: 100vh;
        background-size:cover;
        background-position:center;
       background-color:#394352;
        display: flex;
      align-items: center;
      color: white;
  
   
      }


      .bg1 {
        position:static;
      height: 100vh;
        background-size:cover;
        background-position:center;

        align-items: center;
        justify-content: center;
        display: flex;
      
      }
    
